import React from 'react';
import { IoLogoDropbox } from 'react-icons/io'
import { MdWeb  } from 'react-icons/md'
import { useHistory } from 'react-router-dom';
import { Container, Title, Card, Row } from './styles';

const Home: React.FC = () => {
  const history = useHistory();

  function handleNewProduct() {
    return history.push('/catalogo/inserir');
  }

  function handleNewSite() {
    return history.push('/meus-sites/inserir');
  }

  return (
    <Container>
      <Title>Bem-vindo(a)</Title>
      <Row>
        <Card onClick={handleNewProduct}>
          <h3>Criar novo produto</h3>
          <div className="content">
            <IoLogoDropbox style={{ fontSize: "5rem" }} />
          </div>
        </Card>
        <Card onClick={handleNewSite}>
          <h3>Criar novo site</h3>
          <div className="content">
            <MdWeb style={{ fontSize: "5rem" }} />
          </div>
        </Card>
      </Row>
    </Container >
  );
}

export default Home;