/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {RouteComponentProps} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import useFetch from '../../hooks/useFetch';
import {
  Wrapper,
  HeaderContent,
  Title,
  Content,
  ActionsContainer,
  Button,
  Footer,
  SearchInput,
  Card,
  CardWrapper,
} from './styles';
import { IToggleMenuOption } from '../../components/ToggleMenuButton';
import Toast from '../../components/Toast';
import {PaginateContainer, SelectedContainer} from '../../components/ListTable/styles';
import { useModal } from '../../contexts/modal';
import { orderCols } from '../../utils/form';
import ListTable from '../../components/ListTable';
import AdCard from '../../components/AdCard';

interface IAction {
  id: string;
  name: string;
  type: "global" | "specific";
  onClick?: any;
}

interface ITemplateProps extends RouteComponentProps {
  title: string;
  actions: Array<IAction>;
  endpoint: "entidades"
  | "userGroups"
  | "classes"
  | "permissoes"
  | "pedidos"
  | "titulos"
  | "tabelas"
  | "recursos";
  filters?: any;
  path?: string;
  pullOrderPath?: string;
  multipleSelect?: boolean;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

interface IBaseHandler {
  [key: string]: any;
}

const Adspy: React.FC<ITemplateProps> = ({
  title = 'Adspy',
  actions = [],
  endpoint,
  path = '/adspy',
  pullOrderPath,
  multipleSelect,
  filters,
  history,
  location,
  ...props
}) => {
  const requestHandler = useFetch({endpoint: 'tabela'}) as any;
  const fields = useFetch({endpoint: 'tabela/campos/'});
  const {openModal} = useModal();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(localStorage.getItem(`DevariERP@search${location.pathname || ''}`) || '');
  const [firstLoad, setFirstLoad] = useState(false);
  const [getResponse, setGetResponse] = useState<IResponse | null>(null);
  const [cols, setCols] = useState<Array<any>>([]);
  const [actionsButtons, setActionsButtons] = useState<IAction[]>([]);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);

  const [selectedToAction, setSelectedToAction] = useState<any>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [deleteItem, setDeleteItem] = useState(false);
  const [resetPage, setResetPage] = useState(true);


  const handleInsert = () => {
    if (path) {
      history.push(`${path}/inserir`);
    }
  };

  const handleEdit = async (item: any) => {
    if (path && (item.id || item.chcriacao)) {
      let itemToSend = {
        ...item,
      };

      let state: any = {
        item: itemToSend,
      };

      history.push({
        pathname: `${path}/editar/${item.id ? item.id : item.chcriacao}`,
        state,
      });
    }
  };
  
  const handleRemove = (item: any) => {
    setSelectedToAction(item);
    setDeleteItem(true);
  };

  const baseActionHandlers: IBaseHandler = {
    insert: handleInsert,
    edit: handleEdit,
    remove: handleRemove,
  };

  useEffect(() => {
    setResetPage(true);
  }, [endpoint, filters]);

  useEffect(() => {
    if (resetPage) {
      handleReset();
    }
  }, [resetPage]);

  useEffect(() => {
    if (deleteItem) {
      openModal({
        type: 'confirm',
        title: 'Tem certeza que deseja excluir?',
        children: renderDeleteContent,
        onConfirm: handleDeleteSelected,
        onCancel: () => setDeleteItem(false),
      });
    }
  }, [deleteItem]);

  useEffect(() => {
    if (firstLoad && !search) {
      getData();
    }
  }, [currentPage]);

  useEffect(() => {
    if (search !== null) {
      localStorage.setItem(`DevariERP@search${location.pathname || ''}`, search);
    }
    if (!search && !firstLoad) return;
    let timer = setTimeout(() => {
      getData(false, search);
    }, 500)
    return () => clearTimeout(timer)
  }, [search]);

  useEffect(() => {
    const globalActions = actions.filter(action => action.type === "global");
    const specificActions = actions.filter(action => action.type === "specific");
    const baseKeys = Object.keys(baseActionHandlers);

    if (globalActions.length > 0) {
      const global = globalActions.map(action => {
        const option: IAction = {
          ...action,
          onClick: 
            baseKeys.includes(action.id) 
              ? () => baseActionHandlers[action.id]() 
              : action.onClick 
              ? () => action.onClick()
              : () => {},
        };
        return option;
      });

      setActionsButtons(global);
    };
    if (specificActions.length > 0) {
      const rowActions = specificActions.map((action: IAction, index: number) => {
        const option: IToggleMenuOption = {
          id: index,
          name: action.name,
          onClick: 
            baseKeys.includes(action.id) 
            ? (item: any) =>  baseActionHandlers[action.id](item) 
            : action.onClick 
            ? () => action.onClick()
            : () => {},
        }
        
        return option;
      });
      setActionsMenus(rowActions);
    }
  }, []);

  const handleReset = () => {
    setGetResponse(null);
    setCols([]);
    if (!search) {
      getData(true);
    }
    setResetPage(false);
  }

  const getData = async (firstRender = false, search: string | null = null) => {
    try {
      if (firstRender || cols.length <= 0) {
        setFirstLoad(true);
        let cols = await fields.get(filters);
        cols = cols.filter((field: any) => field.tipo !== 'tabela');
        const order_cols = cols.sort(orderCols);
        setCols(order_cols);
      }
  
      let getFilters: any = {page: currentPage, classe: 'Sites'};
  
      if (filters) {
        getFilters = {
          ...getFilters,
          ...filters,
        }
      }

      if (search) {
        getFilters = {
          ...getFilters,
          nome: search,
        }
      }
  
      if (location && location.state) {
        const state = location.state as any;
        if (state.filtersToGet) {
          getFilters = {
            ...getFilters,
            ...state.filtersToGet,
          }
        } 
      }
      const result = await requestHandler.get(getFilters);
      if (!result.error) {
        setGetResponse(result);
      }
    } catch (err: any) {
      console.log(err);
    }
  }

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  }

  const handleDeleteSelected = async () => {
    if (selectedToAction) {
      await requestHandler.remove(`/${selectedToAction.id}`);
      setDeleteItem(false);
      setTimeout(() => {
        getData();
      }, 1000);
    }
  }

  const renderDeleteContent = () => {
    return (
      <p><b>{selectedToAction.nome ? selectedToAction.nome : selectedToAction.id}</b></p>
    );
  }

  //NOVO

  const baseAds1: any = [
    {
      user: {
        id: 1,
        name: 'Alura',
        photo: 'https://scontent.fbgw14-3.fna.fbcdn.net/v/t39.35426-6/315764417_1392948257901390_7190333107357534728_n.jpg?stp=dst-jpg_s60x60&_nc_cat=102&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=aHzMkGVM9SYAX_4rJTC&_nc_ht=scontent.fbgw14-3.fna&oh=00_AfA_MQn4a-roDJOqzprRWAfl6YzfKwTJ_uUVHPg3SoQuZQ&oe=637897EF',
      },
      ad: {
        id: 1,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/6/b/6325896726551_cards_0.jpg',
        mediaType: 'photo',
        content: 'Oi, sumido! A maior escola Tech do país quer você de volta ♥️com 30% OFF na matrícula 👉 Clique em Saiba Mais e renove seu acesso agora mesmo! ⏳Válido por pouco tempo (aproveite agora!)',
        subcontent: 'Garanta 30% de Desconto Agora Confira as novidades da Alura',
        link: 'https://www.alura.com.br/ofertas/renovacao-black-friday/?utm_source=facebook&utm_medium=cpc&utm_campaign=blackfriday2022&utm_term=bf22-fase3-reativacao-antigos&utm_content=imagem-reativacao-recentes-paulo-V2',
      }
    },
    {
      user: {
        id: 5,
        name: 'Zop',
        photo: 'https://scontent.fcmn1-2.fna.fbcdn.net/v/t39.35426-6/315748167_5418823674894457_6088756512577320690_n.jpg?stp=dst-jpg_s60x60&_nc_cat=109&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=-ZXsb8DqxvwAX9OYiKK&_nc_ht=scontent.fcmn1-2.fna&oh=00_AfA8r1bM09dsj9CI0HPM_-0rDIKARxFUbXuF5kQTrEuiFA&oe=6379F9F1',
      },
      ad: {
        id: 5,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/4/b/23851847164310774_images_0.jpg',
        mediaType: 'photo',
        content: 'Este Buen Fin, compra ahora y paga después: TecnologÍa, moda y accesorios, belleza, calzado, hogar y más a 4 pagos sin intereses con Zip.🛍️',
        subcontent: 'Zip.co tu nueva forma de pago',
        link: 'https://bit.ly/3WgF2ym',
      }
    },
    {
      user: {
        id: 9,
        name: 'BiTrix',
        photo: 'https://scontent.fmnl17-3.fna.fbcdn.net/v/t39.35426-6/315043088_666140014902872_1200981315300802255_n.jpg?stp=dst-jpg_s60x60&_nc_cat=110&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=wBqngLYf4cIAX-T1SGS&_nc_ht=scontent.fmnl17-3.fna&oh=00_AfCpT6agr2SKIcwNOD296LMdfjAf1LyGyaJoC0fk6k676g&oe=6378E00A',
      },
      ad: {
        id: 9,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/e/6/23852408939930047_cards_0.jpg',
        mediaType: 'photo',
        content: 'Se você é uma pessoa comunicativa e tem paixão por tecnologia, além disso acredita que aprender, ensinar e passar conhecimento aos outros te satisfaz, candidate-se imediatamente e venha fazer parte da família SuperGeeks. O Instrutor(a) dará aulas para crianças de 7 até 16 anos, por isso, terá a oportunidade de fazer a diferença no desenvolvimento de crianças e adolescentes. Responsabilidades: Preparação e condução das aulas, acompanhamento pedagógico dos alunos, apoio na definição da programação de',
        subcontent: 'Inscreva-se agora! 👉👉👉👉',
        link: 'https://b24-339ooj.bitrix24.site/crm_form9/',
      }
    },
  ];

  const baseAds2: any = [
    {
      user: {
        id: 2,
        name: 'Icons Store Mexico',
        photo: 'https://scontent-bog1-1.xx.fbcdn.net/v/t39.35426-6/315118922_1186728518867019_3869666213116324710_n.jpg?stp=dst-jpg_s60x60&_nc_cat=103&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=VHEDDAz-SdgAX_pLrnC&_nc_oc=AQkDIS_ToH7OOEgGgdZTQnUhiQcJ6ahJoNBUNUGUkW21gty0N40MONZxcFPuRq7tyX8&_nc_ht=scontent-bog1-1.xx&oh=00_AfAFyuI310hjzfBo0lLu52PQed-aeJNWV4kdgqeQuZUdFw&oe=637A6959',
      },
      ad: {
        id: 2,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/c/f/23851867898910213_cards_0.jpg',
        mediaType: 'photo',
        content: '🔴 COMENZÓ EL BUEN FIN EN ICONS 🔴 ✅ Toda la tienda en línea A PRECIOS DE REMATE y ENVÍOS GRATIS 🚛 !!! 📲 MÁNDANOS UN WHATSAPP Y TE COTIZAMOS LO QUE NECESITAS: (612)1582322',
        subcontent: '#Tecnología #Software #Consumibles #SmartHome',
        link: 'http://instagram.com/icons_store_mexico',
      }
    },
    {
      user: {
        id: 6,
        name: 'Ella Encantadora',
        photo: 'https://scontent.fbgw15-2.fna.fbcdn.net/v/t39.35426-6/315400350_1569127946861926_7091488582578646503_n.jpg?stp=dst-jpg_s60x60&_nc_cat=109&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=PyNZWwRIlj0AX8UFr2p&_nc_ht=scontent.fbgw15-2.fna&oh=00_AfCFTLRGSpBFC29-0xpCoJuPGuaOdw6AxQlGU5l8Ows6Aw&oe=6379269A',
      },
      ad: {
        id: 6,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/a/a/23851784163570113_cards_0.jpg',
        mediaType: 'photo',
        content: 'Chega de gastar rios de dinheiro em salão de beleza! 😍 Últimas Unidades com Desconto especial 😱 Entregamos em sua casa! 🚚 Clique em Saiba Mais e garanta já a sua ⤵️',
        subcontent: 'CABELOS LISOS E BRILHOSOS 🔥 A ESCOVA 5 em 1 PODEROSA QUE DEIXA SEU CABELO LISO E COM BRILHO DE ARRASAR EM MUITO MENOS TEMPO ﻿SECA - ALISA - HIDRATA - MODELA - ANTI-FRIZZ RESULTADO DE SALÃO NO CONFORTO DA SUA CASA ✅ TECNOLOGIA EXCLUSIVA ALTA PERFORMACE ✅ ECONOMIZE TEMPO E DINHEIRO ✅ ALISA SEM QUEIMAR A RAIZ ✅ 5 NÍVE...',
        link: 'https://www.ellaencantadora.com/escova5em1',
      }
    },
    {
      user: {
        id: 10,
        name: 'Impacta',
        photo: 'https://scontent.fasu10-1.fna.fbcdn.net/v/t39.35426-6/314942745_608680931006366_570126666354131563_n.jpg?stp=dst-jpg_s60x60&_nc_cat=106&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=1MyaoncC3ksAX-Zw7Bt&_nc_ht=scontent.fasu10-1.fna&oh=00_AfAYrvMi0YVYqb-o-punexSm4WlfzcCQAgbiJfEUECE9Uw&oe=637797C6',
      },
      ad: {
        id: 10,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/c/7/23852028331120198_cards_0.jpg',
        mediaType: 'photo',
        content: 'Você vai poder trabalhar com desenvolvimento web, mobile e IOT depois de começar sua graduação em ADS aqui na Impacta! ⚡👉 Invista na sua carreira no mercado digital e estude com flexibilidade de horários e localização.',
        subcontent: 'Inscreva-se agora! 👉👉👉👉',
        link: 'https://www.impacta.edu.br/graduacoes/analise-e-desenvolvimento-de-sistemas-ead',
      }
    },
  ];

  const baseAds3: any = [
    {
      user: {
        id: 3,
        name: 'Icons Store Mexico',
        photo: 'https://scontent.fbkk22-8.fna.fbcdn.net/v/t39.35426-6/315835786_3097554593868093_3363322136806550809_n.jpg?stp=dst-jpg_s60x60&_nc_cat=108&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=EU_R5EGQx1QAX-03zs5&_nc_ht=scontent.fbkk22-8.fna&oh=00_AfA029EHwCrj5OnmyLpr1S53RRcz2yJe008YcAtck0_CiQ&oe=637A1043',
      },
      ad: {
        id: 3,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/9/d/23851606989560684_cards_0.jpg',
        mediaType: 'photo',
        content: 'Chegou a hora de ter uma cozinha linda, prática e elegante com móveis planejados 🤍🔨 Com a Riveza Planejados, sua cozinha com móveis sob medida é entregue em até 30 dias e vai contar com: ✅Qualidade industrial com tecnologia alemã; ✅Parcelamento em até 12x sem juros; ✅Tecnologia 3D; ✅Exclusividade e personalização; ✅Acabamento premium e durabilidade. 📱 Entre em contato agora mesmo e solicite um orçamento.',
        subcontent: '🔴Solicite um orçamento',
        link: 'http://instagram.com/',
      }
    },
    {
      user: {
        id: 7,
        name: 'Eba Online',
        photo: 'https://scontent.fbog17-1.fna.fbcdn.net/v/t39.35426-6/315608040_542296984355210_319909474568383032_n.jpg?stp=dst-jpg_s60x60&_nc_cat=105&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=hQ_ohbC6LjAAX_zXB6L&_nc_ht=scontent.fbog17-1.fna&oh=00_AfBuwU5pve5Ll6D8Y2Xxm0WhgqasCbxlQj8kEpAsp4LGtQ&oe=63768A62',
      },
      ad: {
        id: 7,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/6/e/23852645755060578_cards_0.jpg',
        mediaType: 'photo',
        content: 'Com a nossa jornada, você parte do básico e chega até uma página completa front-end, mergulhando nas melhores práticas de desenvolvimento web e trabalhando com HTML, CSS e GitHub. Ao final, você terá construído uma página de currículo para poder apresentar no seu portfólio e ingressar na carreira mais aquecida do mercado, podendo ainda concorrer à seleção de uma bolsa de estudos e a uma viagem para Porto Seguro! Inscreva-se!',
        subcontent: '22 a 29 de novembro de 2022 🔥 Inscreva-se agora! 👉👉👉👉',
        link: 'https://ebaconline.com.br/webinars/jornada-dev-novembro-22-29',
      }
    },
  ];

  const baseAds4: any = [
    {
      user: {
        id: 4,
        name: 'Love Ofertas',
        photo: 'https://scontent.fhyd1-3.fna.fbcdn.net/v/t39.35426-6/314478485_3429347014056181_2339505157444201151_n.jpg?stp=dst-jpg_s60x60&_nc_cat=101&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=Icom9pJRyNUAX80bMp5&_nc_ht=scontent.fhyd1-3.fna&oh=00_AfB4rb5aHtlurZ9zMf9e6odpBw__Z4eLTxVtXgFEIM-1Hg&oe=637A6E19',
      },
      ad: {
        id: 4,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/7/9/23852839474370103_cards_0.jpg',
        mediaType: 'photo',
        content: 'Os cadernos convencionais saíram de moda! Seu maior concorrente chegou ao Brasil para ditar a moda. 😱 Conheça o Caderno Infinito da Newyes. apagável, não amassa e à prova d’água… A inovação tecnológica, que fez muito sucesso nos Estados Unidos, finalmente chegou ao Brasil. 🤯 Prático, fácil de usar e com uma tecnologia futurista, o Caderno Infinito chegou para acabar com os cadernos convencionais que rasgam com facilidade e estragam quando entram em contato com líquidos. 💦 Clique abaixo e garanta já o seu.',
        subcontent: '🔴Solicite um orçamento',
        link: 'https://loveofertas.store/products/caderno-infinito-inteligente-newyes-a6',
      }
    },
    {
      user: {
        id: 8,
        name: 'Concursos',
        photo: 'https://scontent-mia3-2.xx.fbcdn.net/v/t39.35426-6/314659730_444946121044307_8066670454390034075_n.jpg?stp=dst-jpg_s60x60&_nc_cat=110&ccb=1-7&_nc_sid=cf96c8&_nc_ohc=gvlYzOpiBfkAX8tZKk6&_nc_ht=scontent-mia3-2.xx&oh=00_AfCHGgPiTnFEETwOY7iehzTGA3e5fgG_Vocb6Ka1aBxx7g&oe=637768AA',
      },
      ad: {
        id: 8,
        startDate: new Date(),
        media: 'https://f004.backblazeb2.com/file/adheart/teasers/images/1/c/23852259794980398_cards_0.jpg',
        mediaType: 'photo',
        content: 'A Reitoria do Instituto Federal de Educação, Ciência e Tecnologia de Mato Grosso do Sul (IFMS) abre inscrições para Concurso Público de 27 vagas para o magistério federal.',
        subcontent: '04/11 a 08/12 de 2022 🔥 Inscreva-se agora! 👉👉👉👉',
        link: 'http://concurso.idecan.org.br/Concurso.aspx?ID=69',
      }
    },
  ];


  return (
    <Wrapper>
      <HeaderContent direction={actionsButtons && actionsButtons.length >= 1 ? 'column' : 'row'}>
        <Title>{title}</Title>
        {!title.startsWith('Resultados da busca') && <SearchInput header="true" placeholder="Pesquise..." value={search} onChange={({currentTarget}) => setSearch(currentTarget.value)}/>}
        <ActionsContainer direction={actionsButtons && actionsButtons.length >= 1 ? 'column' : 'row'}>
          {actionsButtons?.map(action => (
              <Button
                key={action.name}
                onClick={action.onClick ? () => action.onClick() : () => {}}
              >
                {action.name}
              </Button>
            ))}
        </ActionsContainer>
      </HeaderContent>
      {!title.startsWith('Resultados da busca') && <SearchInput header="false" placeholder="Pesquise..." value={search} onChange={({currentTarget}) => setSearch(currentTarget.value)}/>}
      <Content>
        <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
          {baseAds1.map((ad: any, index: number) => (
            <AdCard key={index} content={ad} />
          ))}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
          {baseAds2.map((ad: any, index: number) => (
            <AdCard key={index} content={ad} />
          ))}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
          {baseAds3.map((ad: any, index: number) => (
            <AdCard key={index} content={ad} />
          ))}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
          {baseAds4.map((ad: any, index: number) => (
            <AdCard key={index} content={ad} />
          ))}
        </div>
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Total: <b>10</b></p>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName='active-page-item'
          />
        </PaginateContainer>
      </Footer>
    </Wrapper>
  );
}

export default Adspy;