/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import TextInput from '../../../components/TextInput';
import {
  Wrapper,
  HeaderContent,
  Title,
  Content,
  FormContainer,
  Button,
  GeneralInfo,
} from '../styles';
import Table from '../../../components/Table';
import { Column } from '../../../components/Table/types';
import ImageInput from '../../../components/ImageInput';
import { useFormik } from 'formik';
import { uploadFile } from '../../../services/upload';
import Toast from '../../../components/Toast';
import { isObject } from '../../../utils/form';
import CardCheck from '../../../components/CardCheck';
import Step from '../../../components/Step';
import { checkDomainName, getTemplates } from '../../../services/tabela';
import Select from '../../../components/SelectInput';
import LookupInput from '../../../components/LookupInput';
import { useAuth } from '../../../contexts/auth';
import { MdCheckCircle, MdError } from 'react-icons/md';

interface IAction {
  id: string;
  name: string;
  type: "global" | "specific";
  onClick?: any;
}

interface ITemplateProps extends RouteComponentProps {
  title: string;
  actions: Array<IAction>;
  endpoint: "entidades"
  | "userGroups"
  | "classes"
  | "permissoes"
  | "pedidos"
  | "titulos"
  | "tabelas"
  | "recursos";
  filters?: any;
  path?: string;
  pullOrderPath?: string;
  multipleSelect?: boolean;
}

const SitesForm: React.FC<ITemplateProps> = ({
  title = 'Novo site',
  actions = [],
  endpoint,
  path = '/meus-sites/inserir',
  pullOrderPath,
  multipleSelect,
  filters,
  history,
  ...props
}) => {
  const location = useLocation<any>();
  const {user} = useAuth();
  const requestHandler = useFetch({endpoint: 'tabela/site'}) as any;
  const [toEdit, setToEdit] = useState<any>(null);
  const [lookups, setLookups] = useState<any>({});
  // const [uploads, setUploads] = useState<any>({});
  // const [tables, setTables] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>({});
  // const [initialUploadStates, setInitialUploadStates] = useState<any>({});
  const [initialLookupStates, setInitialLookupStates] = useState<any>({});
  const [domain, setDomain] = useState<any>({
    ok: null,
  });
  // const [loadedLookups, setLoadedLookups] = useState(false);
  // const [loadedUploads, setLoadedUploads] = useState(false);
  const [templates, setTemplates] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [currentActiveStep, setCurrentActiveStep] = useState<number>(1);
  const domainOptions = [
    {
      name: 'Não tenho domínio',
      id: 0,
    },
    {
      name: 'Sim, usarei um domínio próprio',
      id: 1,
    },
  ];

  useEffect(() => {
    getTemplatesList();
  }, []);

  useEffect(() => {
    if (location && location.state) {
      const baseItem = location?.state?.item;

      if (baseItem) {
        const item = {
          nome: baseItem.descricao,
          nomeDominio: baseItem.nome.replace('.devaribox.co', ''),
          possuiDominio: baseItem.nome.includes('.devaribox.co') ? 'Não tenho domínio' : 'Sim, usarei um domínio próprio',
          dominioDevari: baseItem.nome.includes('.devaribox.co') ? baseItem.nome.replace('.devaribox.co', '') : '',
        }
        const initialLookups = {
          Produto: { byId: baseItem.idRecurso },
        };
        setSelectedTemplate(baseItem.idTipoDocumento);
        setInitialValues(item);
        setInitialLookupStates(initialLookups);
        setToEdit({
          id: baseItem.id,
          ...item,
        });
      }
    }
  }, [location]);

   const getTemplatesList = async () => {
    try {
      const response = await getTemplates();
      setTemplates(response);
    } catch (err: any) {
      Toast.show(err.message, 'error');
    }
   }

   const checkDomain = async (domain: string) => {
    try {
      const response = await checkDomainName(domain);
      setDomain({
        ok: response.ok,
      });
    } catch (err: any) {
      Toast.show(err.message, 'error');
    }
   }

   const setSiteTemplate = (id: string) => {
    if (selectedTemplate === id) {
      setSelectedTemplate('');
    } else {
      setSelectedTemplate(id);
    }
   }

   const handleForward = () => {
    if (currentActiveStep === 2) {
      handleSubmit();
    } else {
      if (selectedTemplate) {
        setCurrentActiveStep(2);
      } else {
        Toast.show('Selecione um template para continuar', 'error');
      }
    }
   }

  const slugify = (...args: (string | number)[]): string => {
    const value = args.join(' ')

    return value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s+/g, '-');
  }


  const validate = () => {}

  const handleSubmit = async () => {
    if (!form.values.nome) {
      Toast.show('Insira o nome do site', 'error');
      return;
    }
    if (!form.values.possuiDominio || (form.values.possuiDominio === 'Sim, usarei um domínio próprio' && !form.values.nomeDominio)) {
      Toast.show('Insira o domínio', 'error');
      return;
    }
    if (!form.values.possuiDominio || (form.values.possuiDominio === 'Não tenho domínio' && !form.values.dominioDevari)) {
      Toast.show('Domínio inválido', 'error');
      return;
    }
    if (!lookups.Produto || !lookups.Produto.value) {
      Toast.show('Selecione um produto', 'error');
      return;
    }
    const payload = {
      idRecurso: lookups.Produto.value,
      idTipoDocumento: selectedTemplate,
      descricao: form.values.nome,
      nome: form.values.possuiDominio === 'Sim, usarei um domínio próprio' ? form.values.nomeDominio.toLowerCase() : form.values.dominioDevari.toLowerCase(),
      dominioProprio: form.values.possuiDominio === 'Sim, usarei um domínio próprio' ? true : false,
      idPessoa: user?.id,
    }
    try {
      if (toEdit) {
        const response = await requestHandler.put(
          `/${toEdit.id}`,
          payload
        );
        if (!response.error) {
          if (history) {
            history.goBack();
          }
          form.setSubmitting(false);
        } else {
          if (toEdit) {
            Toast.show('Verifique as instruções para conectar seu domínio ao Devari Pages.', 'error');
          }
        }
      } else {
        const response = await requestHandler.post(payload);
        if (!response.error) {
          if (history) {
            history.goBack();
          }
          form.setSubmitting(false);
        }
      }
    } catch (err: any) {
      form.setSubmitting(false);
    }
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setDomain({
      ok: null,
    });
  }, [form.values.nome, form.values.dominioDevari]);

  useEffect(() => {
    if (!toEdit)
      form.values.dominioDevari = slugify(form.values.nome);
  }, [form.values.nome]);

  useEffect(() => {
    if (form.values.dominioDevari) {
      if (toEdit && toEdit.dominioDevari === form.values.dominioDevari) return;
      const delayDebounceFn = setTimeout(() => {
        checkDomain(form.values.dominioDevari);
      }, 500)
  
      return () => clearTimeout(delayDebounceFn)
    }
  }, [form.values.dominioDevari]);

  function handleChangeLookup(field: string, value: any) {
    return setLookups((prevState: any) => {
      return { ...prevState, [field]: value };
    });
  }

  return (
    <Wrapper>
      <HeaderContent direction={'row'}>
        <Title>{toEdit ? 'Editar site' : title}</Title>
      </HeaderContent>
    <FormContainer>
      <Step
        active={currentActiveStep}
      />
      {currentActiveStep === 1 && templates && templates.map((template: any, index: number) => (
        <CardCheck
          key={index}
          image={template.foto}
          name={template.nome}
          selected={selectedTemplate === template.id}
          onClick={() => setSiteTemplate(template.id)}
        />
      ))}
      {currentActiveStep === 2 && (
        <GeneralInfo>
          <TextInput
            id="nome"
            name="nome"
            placeholder="Digite o nome do site..."
            label="Nome"
            require={true}
            value={form.values.nome}
            error={form.errors.nome ? form.errors.nome as string : ''}
            onChange={form.handleChange}
          />
          <Select
            label={'Você possui um domínio?'} 
            id={'possuiDominio'} 
            options={domainOptions}
            require={true}
            hint={`Caso não possua um domínio, seu site será hospedado em nosso domínio (${'https://algo.devaribox.co/'}).`}
            value={form.values.possuiDominio}
            error={form.errors.possuiDominio ? form.errors.possuiDominio as string : ''}
            onChange={form.handleChange}
          />
          {form.values.possuiDominio === 'Não tenho domínio' && (
            <div className="our-domain">
              <TextInput
                id="dominioDevari"
                name="dominioDevari"
                placeholder="Digite o domínio do site..."
                label="Domínio"
                hint="Insira apenas o domínio, sem o 'https://' ou 'www'."
                require={true}
                value={form.values.dominioDevari}
                error={form.errors.dominioDevari ? form.errors.dominioDevari as string : ''}
                onChange={form.handleChange}
              />
              <p>.devaribox.co</p>
            </div>
          )}
          {form.values.possuiDominio === 'Não tenho domínio' && domain.ok === false && (
            <div className="feedback error">
              <MdError />
              <p>Este domínio já está sendo utilizado. Caso não deseje alterar, seu site será gerado sob um modificação deste domínio.</p>
            </div>
          )}
          {form.values.possuiDominio === 'Não tenho domínio' && domain.ok === true && (
            <div className="feedback success">
              <MdCheckCircle />
              <p>Este domínio está livre.</p>
            </div>
          )}
          {form.values.possuiDominio === 'Sim, usarei um domínio próprio' && (
            <>
            <div className="instructions">
              <p>Siga as instruções abaixo para conectar seu domínio ao Devari Pages:</p>
              <div>
                <div>1</div>
                <div>
                  <p>Entre no painel de controle do seu domínio, vá na seção de domínios e encontre o editor de DNS.</p>
                  <p>Crie um novo registro A com as seguintes configurações:</p>
                  <ul>
                    <li>Host/Alias: <i><b>seu domínio (ex: exemplo.com.br)</b></i></li>
                    <li>Destino/Conteúdo: <i><b>172.96.172.218</b></i></li>
                  </ul>
                </div>
              </div>
            </div>
            <TextInput
              id="nomeDominio"
              name="nomeDominio"
              placeholder="Digite seu domínio..."
              label="Domínio"
              hint="Insira apenas o domínio, sem o 'https://' ou 'www'."
              require={true}
              value={form.values.nomeDominio}
              error={form.errors.nomeDominio ? form.errors.nomeDominio as string : ''}
              onChange={form.handleChange}
            />
            </>
          )}
          <LookupInput
            initialState={initialLookupStates.Produto}
            label="Esse site está relacionado a qual produto?"
            labelField='nome'
            endpoint="recursos/produto-site"
            field="nome"
            filters={{
              classe: "Produtos",
            }}
            multi={false}
            require={true}
            onChange={(options) =>
              handleChangeLookup("Produto", options)
            }
          />
        </GeneralInfo>
      )}
      <div className="row">
        {currentActiveStep > 1 && (
          <Button type="button" onClick={() => setCurrentActiveStep(currentActiveStep - 1)}>Anterior</Button>
        )}
        {currentActiveStep <= 1 && (
          <div />
        )}
        <Button
          type="button"
          onClick={handleForward}
        >{currentActiveStep === 2 ? toEdit ? 'Salvar' : 'Criar site' : 'Próximo'}</Button>
      </div>
    </FormContainer>
    </Wrapper>
  );
}

export default SitesForm;