/* eslint-disable react-hooks/exhaustive-deps */
import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import { strIsOnlyNumber } from '../../utils/validation';
import { Wrapper, Label, Input, MaskInput, Check, CurrencyInput } from './styles';
interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  className?: string;
  error?: string;
  readonly?: boolean;
  width?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
  fullWidth?: boolean;
  masked?: boolean;
  maxDigits?: any;
  money?: boolean;
  require?: boolean;
  mask?: string | (string | RegExp)[];
  onChange?(target: any): void;
  inTable?: boolean;
  fixed?: boolean;
  isAddress?: boolean;
  disabled?: boolean;
  precision?: number;
  hint?: string;
}

function floatPrecision(a: number) {
  if (!isFinite(a)) return 0;
  var e = 1, p = 0;
  while (Math.round(a * e) / e !== a) { e *= 10; p++; }
  return p;
}

const TextInput: React.FC<IInputProps> = ({
  id,
  label,
  className,
  error,
  readonly = false,
  width = "md",
  fullWidth = false,
  masked,
  type,
  mask = '',
  money,
  inTable,
  fixed,
  isAddress,
  require,
  precision,
  maxDigits,
  hint,
  ...props
}) => {
  const [value, setValue] = useState<any>(type === "checkbox" ? props.checked : props.value);
  const [numberPrecision, setNumberPrecision] = useState(precision !== null ? precision : 2);
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    if (props.onChange) {
      if (value !== undefined) {
        props.onChange({
          target: {
              id,
              name: props.name,
              value,
          },
        });
      }
    }
  }, [value]);

  useEffect(() => {
    setNumberPrecision(precision);
  }, [precision]);

  useEffect(() => {
    if (props.value !== null && props.value !== undefined && (!hasRendered || isAddress)) {
      setHasRendered(true);
      setValue(props.value);
    } else if(props.value !== value) {
      setValue(props.value);
    } else if (type === "checkbox" && props.checked !== value && !hasRendered) {
      setHasRendered(true);
      setValue(props.checked);
    }
  }, [hasRendered, props.value, props.checked, isAddress]);

  useEffect(() => {
    if (type === 'checkbox') {
      if(!hasRendered) return;
      if (fixed && props.checked) {
        setValue(props.checked);
      }
    } else {
      if (fixed && props.value) {
        setValue(props.value);
        if (money) {
          const decimal = floatPrecision(parseFloat(`${props.value}`));
          if (decimal > 2) {
            setNumberPrecision(decimal);
          };
        }
      }
    }
  }, [props.value, props.checked, fixed, hasRendered]);

  function cpfCnpjMask() {
    if (!value || value.length === 0) 
      return "999999999999999999";

    if (value.replace(/\D/g, "").length < 12) {
      return "999.999.999-999999";
     } else {
      return "99.999.999/9999-99";
    }
  }

  function codigoMask() {
    if(strIsOnlyNumber(value)) {
      if(value.length === 6) {
        return '999.999';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  function checkMaskInput (id: string) {
    if(id === 'cpfCnpj') {
      return cpfCnpjMask();
    } else if(id === 'codigo') {
      return codigoMask();
    }
    return mask
  }

  function handleChangeInputMask(id:any, target:any) {
    setValue((oldValue:any) => {
      if(id === 'codigo' && oldValue) {
        if(oldValue.length === 7 && !strIsOnlyNumber(oldValue)) {
          const fixedStr = target.value.replaceAll('.','');
          return fixedStr;
        }
        return target.value;
      }
      return target.value;
    })
  }
  
  return (
    <Wrapper
      className={className} row={type === 'checkbox'}>
      {type === 'checkbox' && (
        <Check 
          id={id}
          {...props}
          checked={value}
          onChange={() => setValue(!value)}
          width={width}
          error={error}
        />
      )}
      {!inTable && (
        <>
        <Label error={error ? true : false} bold={require} htmlFor={id}>
          {label} {
            error ? (
            <span>(* {error})</span>
            ) : require ? <span> *</span> : null
          }
        </Label>
        {hint && <span className='hint'>{hint}</span>}
        </>
      )}
      {type !== 'checkbox' && money && masked && (
        <CurrencyInput
          id={id}
          {...props}
          value={value ? value : null}
          onChange={(_event: any, maskedValue: any) => setValue(maskedValue)}
          width={width}
          error={error}
          mask={value ? mask : false}
          decimalSeparator=","
          thousandSeparator="."
          precision={numberPrecision}
        />
      )}
      {type !== 'checkbox' && !money && masked && mask === 'integer' && (
        <Input 
          id={id}
          type={type ? type : 'text'}
          {...props}
          value={String(value) !== 'undefined' && String(value) !== 'NaN' && String(value) !== 'null' ? String(value) : ''}
          onChange={({target}) => maxDigits ? setValue(parseInt(target.value.substring(0, maxDigits))) : setValue(parseInt(target.value))}
          width={width}
          error={error}
        />
      )}
      {type !== 'checkbox' && !money && masked && mask !== 'integer' && (
        <MaskInput
          id={id}
          type={type ? type : 'text'}
          {...props}
          value={value}
          onChange={({target}) => handleChangeInputMask(id, target)}
          width={width}
          error={error}
          mask={checkMaskInput(id)}
        />
      )}
      {type !== 'checkbox' && !money && !masked && (
        <Input 
          id={id}
          type={type ? type : 'text'}
          {...props}
          value={value}
          onChange={({target}) => setValue(target.value)}
          width={width}
          error={error}
        />
      )}
    </Wrapper>
  );
}

export default TextInput;