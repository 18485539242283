import styled from 'styled-components';
import AsyncSelectLayout from 'react-select/async';

interface ILabel {
  bold?: boolean;
  error?: boolean;
}

interface IToolTip {
  show: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 4px;
  transition: .2s;
  padding: 2px 0 2px 0;
  min-width: 30%;
`;

export const Label = styled.p<ILabel>`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-overflow: none;
  color: ${props => props.error ? 'red' : 'auto'};

  span {
    color: red;
  }
`;

export const ToolTip = styled.p<IToolTip>`
  background-color: black;
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 3px;
  padding: 2px 5px;
`;

export const AsyncSelect = styled(AsyncSelectLayout)`
  & > div {
    box-shadow: none;
  }

  & > div:nth-child(3) {
    width: 100%;
    height: 30px;
    border: 1px solid var(--opacity-background);
    height: auto;
    border-radius: 4px;
    padding: 0px 8px 0px 8px;
    white-space: nowrap;
    box-shadow: none;
    font-size: 14px;
    font-weight: 300;
    color: red;

    & > div {
      padding: 0;
    }

    &:focus {
      background-color: var(--red);
      border: 1px solid var(--primary);
    }
  }
`;