import styled from "styled-components";

export const Container = styled.button`
    width: 250px;
    background-color: var(--white);
    border-radius: 4px;
    cursor: pointer;
    transition: .2s;

    box-shadow: 5px 7px 3px -2px rgba(0,0,0,0.2);

    & > div img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    & > div:last-child {
        text-align: left;
        padding: 8px;
        transition: .2s;
        display: flex;

        align-items: center;
    }

    &:hover {
        filter: brightness(0.9);
    }
    

    & .select {
        border: 1px solid var(--muted);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .select.active {
        & > div {
            background-color: var(--primary);
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
`;