import React from 'react';
import { Container, Content, RoutesContainer } from './styles';

import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import Footer from '../Footer';

import AuthRoutes from '../../navigation/auth';
import { useMenu } from '../../contexts/menu';

const Layout: React.FC<any> = (props) => {
  const { isHTMLEditor } = useMenu();
  const path = props?.location?.state?.from?.state?.from?.pathname;
  const pathname = path ? path : null;
  return (
    <Container removeLimit={isHTMLEditor ? 'true' : 'false'}>
      <Header />
      <Content>
        {!isHTMLEditor && (<LeftSidebar />)}
        <RoutesContainer removeLimit={isHTMLEditor ? 'true' : 'false'}>
          <AuthRoutes initialPath={pathname} />
        </RoutesContainer>
        {!isHTMLEditor && (<Footer />)}
      </Content>
      <RightSidebar />
    </Container>
  );
}

export default Layout;