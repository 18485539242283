import React, { useEffect, useState, useRef } from 'react';
import {Container} from './styles';

import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-webpage';
import blocksBasic from 'grapesjs-blocks-basic';
import gjsForms from 'grapesjs-plugin-forms';
import { useMenu } from '../../contexts/menu';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import Toast from '../../components/Toast';
import { updateSiteFile } from '../../services/tabela';

const Builder: React.FC<any> = ({
  history,
}) => {
  const location = useLocation<any>();
  const { toggleEditor } = useMenu();
  const [idHTML, setIdHTML] = useState<any>(null);
  const [idCSS, setIdCSS] = useState<any>(null);
  const ref = useRef<any>(null);
  
  useEffect(() => {
    toggleEditor();
    getData();
  }, []);

  useEffect(() => {
    return () => {
      toggleEditor();
    }
  });

  async function getData() {
    const data = location.state.data;

    if (!data) return history.goBack();

    const html = data.filter((item: any) => item.observacao === 'html')[0];
    const css = data.filter((item: any) => item.observacao === 'css')[0];

    const ev = grapesjs.init({
      container: '#gjs',
      fromElement: true,
      width: 'auto',
      storageManager: false,

      panels: { defaults: [] },
      plugins: [
        editor => plugin(editor, { /* options */ }),
        editor => gjsForms(editor, { /* options */ }),
        blocksBasic,
      ],    
    });
    setIdHTML(html.id);
    setIdCSS(css.id);
    ev.setComponents(html.codigo);
    ev.setStyle(css.codigo);
    ref.current = ev;
  }

  async function handleSubmit() {
    try {

      if (!idHTML) {
        return Toast.show('Arquivo não encontrado. Tente novamente mais tarde.', 'error');
      }

      if (!idCSS) {
        return Toast.show('Arquivo de estilos não encontrado. Tente novamente mais tarde.', 'error');
      }

      const html = ref.current.getHtml();
      const css = ref.current.getCss();

      await updateSiteFile(idHTML, html);
      await updateSiteFile(idCSS, css);

      return Toast.show('Design salvo com sucesso!', 'success');
    } catch (err: any) {
      return Toast.show('Ocorreu um erro ao tentar salvar o novo design', 'error');
    }
  }

  return (
    <Container>
      <div className="row">
        <Button type="button" onClick={() => history.goBack()}>Voltar</Button>
        <Button type="button" onClick={handleSubmit}>Salvar</Button>
      </div>
      <div id="gjs">
      </div>
    </Container>
  );
};

export default Builder;