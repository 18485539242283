import styled from 'styled-components';

export const Container = styled.div<any>`
  width: 100%;
  height: 100%;
  background-color: var(--background);
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 60px);
  transition: .2s;
  overflow: hidden;
  position: relative;
`;

export const RoutesContainer = styled.div<any>`
  width: 100%;
  height: ${props => props.removeLimit === 'false' ? 'calc(100% - 60px)' : '100%'};
  padding: ${props => props.removeLimit === 'false' ? '16px' : '0px'};
  transition: .2s;
  overflow: auto;
`;