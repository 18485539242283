import styled from 'styled-components';

import InputLayout from '../../components/TextInput';
import ButtonLayout from '../../components/Button';
import LinkButtonLayout from '../../components/LinkButton';
import InputMask from 'react-input-mask';

import Background from '../../assets/images/bg2.webp';
import { AiOutlineEyeInvisible } from 'react-icons/ai';

interface IWide{
  wide?: string;
}

export const Container = styled.div`
  background-image: url(${Background});
  background-size: cover;
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;

  @media (max-width: 599px) {
    overflow: auto;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  background-color: var(--white);
  width: 35%;
  height: 100%;
  padding: 16px 32px;
  box-shadow: 5px 7px 3px -2px rgba(0,0,0,0.41);
  display: flex;
  flex-direction: column;
  justify-content: center;

  & form fieldset:nth-last-child(3n){
    margin-bottom: 32px;
  }

  @media(max-width: 1048px) {
    & {
      width: 80%;
    }
  }

  @media(max-width: 420px) {
    & {
      width: 90%;
    }
  }
`;

export const Form = styled.form`
  & > p:last-child {
    margin-top: 64px;
    text-align: center;
    
    & a {
      color: var(--primary);
      text-decoration: none;
    }
  }
`;

export const Input = styled(InputLayout)<IWide>`
  margin-bottom: 16px;
  width: ${props => props.wide ? props.wide : ''};

  @media (max-width: 599px) {
    width: 100%;
  }
`;

export const Button = styled(ButtonLayout)`
  margin-top: 10px;
  margin-bottom: 16px;

  & > span > svg{
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
`;

export const Link = styled(LinkButtonLayout)`
  color: var(--white);
  margin-bottom: 16px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 50px;
  object-fit: contain;
  position: absolute;
  right: calc(35% - 16px);
  top: 16px;
`;

export const Title = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: var(--primary-text-color);
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: var(--muted);
  margin-bottom: 32px;
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button{
    background: transparent;
    margin-left: auto;
    margin-right: 6px;
    margin-bottom: -60px;
    z-index: 1;
  }

  svg{
    font-size: 30px;
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;

export const ColumnInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 49%;

  @media (max-width: 599px) {
    width: 100%;
  }
`;

export const MaskInput = styled(InputMask)`
  height: 35px;
  font-size: 16px;
  padding: 0px 8px 0px 8px;
`;