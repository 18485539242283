import React, { InputHTMLAttributes } from 'react';
import { Wrapper, Label, Select } from './styles';

interface ISelectOption {
  id: number | string;
  name: string;
}

interface ISelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  className?: string;
  error?: string;
  disabled?: boolean;
  require?: boolean;
  options?: Array<ISelectOption>;
  width?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
  fullWidth?: boolean;
  inTable?: boolean;
  validError?: boolean;
  hint?: string;
}

const SelectInput: React.FC<ISelectProps> = ({
  id,
  label,
  className,
  error,
  disabled = false,
  options,
  width = 'md',
  fullWidth = false,
  require,
  inTable,
  validError,
  hint,
  ...props
}) => {
  return (
    <Wrapper className={className} >
      {!inTable && (
        <>
        <Label
          validateError={validError ? true : false}
          htmlFor={id}
          width={width}
          bold={require}
          fullwidth={fullWidth}>
          {label} {require ? <span> *</span> : null}
        </Label>
        {hint && <span className="hint">{hint}</span>}
        </>
      )}
      <Select
        id={id}
        disabled={disabled}
        width={width}
        fullwidth={fullWidth}
        error={error}
        {...props}>
        <option value="-1">Selecione...</option>
        {options?.map(option => (
          <option key={option.id} value={option.name}>{option.name}</option>
        ))}
      </Select>
    </Wrapper>
  );
}

export default SelectInput;