/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosRequestHeaders } from 'axios';
import React, {useState, useEffect, useRef} from 'react';
import { ActionMeta } from 'react-select';
import { IoAddCircleOutline } from 'react-icons/io5';
// import { MdContentCopy } from 'react-icons/md';
import {Container, Label, AsyncSelect, ToolTip} from './styles';

import FormTemplate from '../../components/Template/forms';
import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../contexts/auth';
import { useFormModal } from '../../contexts/formModal';
import { treeify } from '../../utils/format';
import Toast from '../Toast';
import ReactTooltip from 'react-tooltip';

export interface ISelectItem {
  label: string;
  value: string;
}
interface Props {
  key?: string;
  label: string;
  endpoint: string;
  field: string;
  labelField?: string;
  uf?: string;
  filters?: any;
  multi?: boolean;
  token?: string;
  onChange?(options: any): void;
  disabled?: boolean;
  initialState?: any;
  data?: any;
  require?: boolean;
  error?: boolean;
  disableFormModal?: boolean;
}

export const treatClasses = (classesList: any, level: number = 1) => {
  let result: any[] = [];
  for (const classe of classesList) {
    const novoNome = `${".\xa0\xa0\xa0".repeat(level)} ${classe.nome}`;
    result.push({
      ...classe,
      nome: novoNome,
    });

    if (classe.children) {
      const children = treatClasses(classe.children, level + 1);
      result = [...result, ...children];
    }
  }

  return result;
}

export function removeSpecials(str: string): string {
  if (!str) return '';
  var lower = str.toLowerCase();
  var upper = str.toUpperCase();

  var res = "";
  for(var i=0; i<lower.length; ++i) {
      if(lower[i] !== upper[i] || lower[i].trim() === '')
          res += str[i];
  }
  return res;
}

const LookupInput: React.FC<Props> = ({
  key, label, endpoint, uf, field, labelField, filters, 
  token, disabled, onChange, data: requestData, 
  initialState, multi = true, require, error,
  disableFormModal
}) => {
  const lookupHandler = useFetch({endpoint});
  const lookupRef = useRef<any>(null);
  const {openModal, closeModal} = useFormModal();
  const {user} = useAuth();
  const [value, setValue] = useState<ISelectItem[]>([]);
  const [baseOptions, setBaseOptions] = useState<ISelectItem[]>([]);
  const [tooltip, _setTooltip] = useState('');
  const tooltipRef = React.useRef(tooltip);
  const isClasses = endpoint === 'classes' || endpoint === '/classes' || endpoint === '/classes/';
  const showPlusIcon = endpoint !== "/classes" ? disableFormModal ? false : true : false;

  useEffect(() => {
    if (lookupRef && lookupRef.current !== null) {
      lookupRef.current.addEventListener('copy', (event: any) => {
        event.preventDefault();
        handleCopyToClipboard(tooltipRef.current || '');
      });
    }
    return () => window.removeEventListener('copy', () => {});
  }, [lookupRef]);

  useEffect(() => {
    if (initialState) {
      if (initialState.byName) {
        loadOptions(initialState.byName, true);
      } else if(initialState.byId && requestData) {
        const { byId } = initialState;
        if(Array.isArray(byId)) {
          setValue(byId);
          handleChange(byId);
        } else if (typeof byId === 'string') {
          const _value = {
            label: byId,
            item: byId,
            value: byId
          } as any;

          setValue(_value as ISelectItem[]);
          handleChange(_value as ISelectItem[])
        }
      } else if(initialState.byId && Array.isArray(initialState.byId)) {
        loadMultiOptions(initialState);
      } else if (initialState.byId) {
        loadOptions(initialState.byId, true, true);
      } else {
        setValue(initialState);
        handleChange(initialState);
      }
    }
  }, [initialState]);

  useEffect(() => {
    if(uf && initialState && Object.keys(initialState).length <= 0){
      loadBaseOptions();
    }
  }, [uf])

  useEffect(() => {
    loadBaseOptions();
  }, [])

  const setTooltip = (data: string) => {
    tooltipRef.current = data;
    _setTooltip(data);
  };

  const loadBaseOptions = async() => {
    const options = await loadOptions('', true);
    setBaseOptions(options);
  }

  const loadMultiOptions = async (initialState: any) => {
    const { byId } = initialState;
    let arr = [];
    for(const item of byId) {
      const res = await loadOptions(item.value, true, true);
      arr.push(...res);
    }
    handleChange(arr);
  }

  const loadOptions = async (
    inputValue: string,
    loadInitialState: boolean = false,
    isId?: boolean,
  ) => {
    if (!loadInitialState) return;
    const headers: AxiosRequestHeaders = {};
    const userToken = token ? token : user?.token;

    if (userToken) {
        headers.Authorization = `Bearer ${userToken}`;
    }
    
    let params: any = {
      // page: 1,
    };

    if (inputValue) {
      if (isId) {
        params = { ...params, id: inputValue };
      } else {
        params = { ...params, [field]: inputValue, };
      }
    } else {
      params = {...params, page: 1, take: 5};
    }

    if (uf) {
      params = {...params, uf};
    }

    if (filters) {
        params = {...params, ...filters, };
    }

    if (isClasses) {
      params = {
        ...params,
        all: true,
      }
    }

    if (requestData) {
      const formatted = requestData.map((item: any) => {
        return {
            value: item,
            label: item,
            item,
        };
      });

      return formatted;
    }
    
    let data = await lookupHandler.get(params, headers);

    if (data.docs) {
      data = data.docs;
    }
    
    if (data.length > 0 && isClasses) {
      const withChildrenClasses = [];

      for (let i = 0; i < data.length; i++) {
        const classe = data[i];
        if (!classe.hasOwnProperty('agrupamento') || classe.agrupamento === false) {
          withChildrenClasses.push({ ...classe, children: [] });
        }
      }
      const arvoreClasses = treeify(withChildrenClasses, 'idPai');
      data = treatClasses(arvoreClasses);
    }

    const formatted = [];

    if (data.length <= 0 && initialState) {
      handleChange([]);
    }
    for (const item of data) {
      
      let found = false;
      const codigo = labelField && item[labelField] ? item[labelField] : `${item.codigo ? item.codigo : ''}${item.nome ? ` - ${item.nome}` : ''}`;
      if (initialState) {
        if (initialState.byName) {
          let itemNome = item.nome ? item.nome.toLowerCase() : '';
          let initialStateByName = initialState.byName ? initialState.byName.toLowerCase() : '';
          let itemCodigo = item.codigo ? item.codigo.toLowerCase() : '';
          if (isClasses) {
            if (itemNome.includes(initialStateByName) || itemCodigo.includes(initialStateByName)) {
              handleChange({
                value: item.id,
                label: isClasses ? removeSpecials(item.nome).trim() : codigo,
                item,
              });
              found = true;
            } else {
              handleChange([])
            }
          } else {
            if (itemNome === initialStateByName || itemCodigo === initialStateByName) {
              handleChange({
                value: item.id,
                label: isClasses ? removeSpecials(item.nome).trim() : codigo,
                item,
              });
              found = true;
            } else {
              handleChange([])
            }
          }
        } else if(Array.isArray(initialState.byId)) {
          const some = initialState.byId.some((_initState:any) => _initState.value === item.id);
          if(some) {
            found = true;
          }
        } else if (initialState.byId && item.id === initialState.byId) {
          handleChange({
            value: item.id,
            label: isClasses ? removeSpecials(item.nome).trim() : codigo,
            item,
          });
          found = true;
        }
      } 

      formatted.push({
          value: item.id,
          label: isClasses ? item.nome : codigo,
          item,
      });

      if (found) {
        break;
      }
    }

    return formatted;
  };

  const handleChange = (newValue: unknown, actionMeta?: ActionMeta<unknown>) => {
    if (onChange && newValue) {
      let optionValue: any = newValue;
      
      if (isClasses && optionValue && !Array.isArray(optionValue)) {
        optionValue = {
          ...optionValue,
          label: removeSpecials(optionValue.label).trim(),
        }
      }
      const options = optionValue as ISelectItem[];
      onChange(options);
      setValue(options);
    } else if (onChange && newValue === null) {
      onChange(null);
      setValue([]);
    }
  }

  const handleSubmitModal = (item: any) => {
    const newValue = {
      value: item.id,
      label: item.nome,
      item,
    };
    setBaseOptions(prev => [...prev, newValue]);
    handleChange(newValue);
    closeModal();
  }

  const handleOpenModal = () => {
    openModal({
      type: 'confirm',
      title: `Adicionar ${label}`,
      children: () => <FormTemplate 
        filters={{...filters}}
        customFields={{}}
        title={label}
        endpoint={`${endpoint}`} 
        onSubmitCallback={handleSubmitModal}/>,
    })
  }

  const handleMouseEnter = () => {
    const _value = value as any;
    if (_value.item) {
      const item = _value.item;
      const codigo = `${item.codigo ? item.codigo : ''}${item.nomeFantasia ? ` - ${item.nomeFantasia}` : item.nome ? ` - ${item.nome}` : ''}`;
      setTooltip(codigo);
    } else if(_value.label) {
      setTooltip(_value.label);
    }
  }

  const handleCopyToClipboard = (_value: string) => {
    navigator.clipboard.writeText(_value);
    Toast.show('Texto copiado com sucesso', 'success');
  }
  
  return (
    <Container ref={lookupRef}>
        <Label error={error ? true : false} bold={require}>{label} 
        {require ? <span> *</span>:null}
        {/* {showPlusIcon ? <IoAddCircleOutline style={{cursor: 'pointer', marginBottom: -3, marginLeft: 4}} type="button" size={15} onClick={handleOpenModal} color="var(--primary-text-color)" /> : null} */}
        {/* <MdContentCopy style={{cursor: 'pointer', marginBottom: -3, marginLeft: 4}} type="button" size={15} onClick={handleCopyToClipboard} color="var(--primary-text-color)" /> */}
        </Label>
          {tooltip && <ReactTooltip id="global" place="top" effect="float" textColor='transparent' backgroundColor='transparent'>
            <ToolTip show={tooltip ? true : false}>{tooltip}</ToolTip>
          </ReactTooltip>}
          <div data-tip data-for="global" onMouseEnter={handleMouseEnter} onMouseLeave={() => setTooltip('')}>
            <AsyncSelect
                isDisabled={disabled}
                isMulti={multi}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                maxMenuHeight={150}
                value={value}
                loadOptions={loadOptions}
                defaultOptions={baseOptions}
                onChange={handleChange}
                isClearable
                noOptionsMessage={() => "Nenhum item"}
                placeholder=""
                loadingMessage={() => "Carregando..."}
            />
          </div>
    </Container>
  );
}

export default LookupInput;