import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 4px;

  box-shadow: 5px 7px 3px -2px rgba(0,0,0,0.2);

  & img {
    max-width: 300px;
  }

  & > div:first-child {
    display: flex;
    height: 50px;

    & img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    & > div:last-child {
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & p {
            font-size: 18px;
            color: var(--primary);
            font-weight: 500;
        }

        & span {
            font-size: 13px;
            color: var(--muted);
            font-weight: 300;
            margin-top: 2px;
        }
    }
  } 

  & > div:last-child {

    & > div:nth-child(2) {
        line-height: 1.7;

        & > p:first-child {
            font-size: 13px;
            padding: 8px;
        }

        & > p:last-child {
            font-size: 12px;
            padding: 8px;
            color: var(--muted);

            background-color: var(--opacity-background);
        }
    }

    & > div input {
        padding: 8px;
        font-size: 10px;
        width: 100%;
    }
  }
`;
