import React from 'react';
import Button from '../Button';
import { Container } from './styles';

interface IStepProps {
    active: number;
}

const Step: React.FC<IStepProps> = ({active}) => {
  return (
    <Container>
        <div className={active === 1 ? 'active' : ''}>
            <h1><div>1</div> Escolha do Template</h1>
        </div>
        <div className={active === 2 ? 'active' : ''}>
            <h1><div>2</div> Informações do Site</h1>
        </div>
    </Container>
  );
}

export default Step;