import React, {createContext, useState, useContext} from 'react';

type RightMenuType = "notificação" | "perfil";

interface MenuContextData {
  isRightMenuOpen: boolean;
  isLeftMenuOpen: boolean;
  isHTMLEditor: boolean;
  leftMenuWidth: number;
  rightMenuType: RightMenuType;
  setLeftMenuWidth(width: number): void;
  toggleLeftMenu(): void;
  toggleEditor(): void;
  toggleRightMenu(type: RightMenuType): void;
}

const MenuContext = createContext<MenuContextData>({} as MenuContextData);

const MenuProvider: React.FC = ({children}) => {
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [isHTMLEditor, setIsHTMLEditor] = useState(false);
  const [rightMenuType, setRightMenuType] = useState<RightMenuType>("notificação");
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [leftMenuWidth, setLeftMenuWidth] = useState(0);

  const toggleLeftMenu = () => {
    setIsLeftMenuOpen(!isLeftMenuOpen)
  };

  const toggleEditor = () => {
    setIsHTMLEditor(!isHTMLEditor)
  };

  const toggleRightMenu = (type: RightMenuType) => {
    setIsRightMenuOpen(!isRightMenuOpen);
    if (!isRightMenuOpen === false) {
      setTimeout(() => {
        setRightMenuType(type);
      }, 200);
    } else {
      setRightMenuType(type);
    }
  };

  return (
    <MenuContext.Provider
      value={{
        isRightMenuOpen,
        rightMenuType,
        isLeftMenuOpen,
        isHTMLEditor,
        leftMenuWidth,
        setLeftMenuWidth,
        toggleLeftMenu,
        toggleEditor,
        toggleRightMenu,
      }}>
      {children}
    </MenuContext.Provider>
  );
};

function useMenu() {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider.');
  }

  return context;
}

export {MenuProvider, useMenu};