import React, { useState} from 'react';
import {useFormik, FormikHelpers} from 'formik';

import { 
  Container, 
  PaddingContainer,
  Content,
  Title, 
  ProfilePhotoContainer, 
  ProfilePhotoButton, 
  ProfilePhoto, 
  ProfilePhotoButtonOverlay,
  ProfileInfoContainer,
  ProfileSaveContainer,
  Form,
  Row,
} from './styles';
import {IoMdExit} from 'react-icons/io';

import { useAuth } from '../../contexts/auth';
import Button from '../Button';
import FormInputs from '../FormInputs';
import { uploadFile } from '../../services/upload';

interface IEntidadePayload {
  id?: string;
  nome?: string;
  email?: string;
  telefone?: string;
  celular?: string;
  foto?: string;
  cep?: string;
  cpfCnpj?: string;
  endereco?: string;
  numeroEndereco?: string;
  bairro?: string;
  idCidade?: string;
  cidade?: string;
  idUF?: string;
  uf?: string;
  complemento?: string;
  idEstab?: string;
}

export interface IEntidadeValidation {
  nome?: string;
  email?: string;
  telefone?: string;
  celular?: string;
  cep?: string;
  foto?: string;
  cpfCnpj?: string;
  endereco?: string;
  numeroEndereco?: string;
  bairro?: string;
  idCidade?: string;
  idUF?: string;
  complemento?: string;
  idEstab?: string;
}

const Profile: React.FC = () => {
  const {user, signOut, updateUser} = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [uploads, setUploads] = useState<any>();
  const [lookups, setLookups] = useState<any>({});
  const initialValues: IEntidadePayload = {
    nome: user?.nome || '',
    email: user?.email || '',
    // telefone: user?.telefone || '',
    // celular: user?.celular || '',
    // cep: user?.cep || '',
    // cpfCnpj: user?.cpfCnpj || '',
    // endereco: user?.endereco || '',
    // numeroEndereco: user?.numeroEndereco || '',
    // bairro: user?.bairro || '',
    // complemento: user?.complemento || '',
    // idEstab: user?.idEstab || '',
  };
  const fields = {
    nome: {
      id: 'nome',
      placeholder: 'Digite seu nome...',
      label: 'Nome',
      // value: initialValues.nome ? initialValues.nome : '',
      onChange: (options: any) => handleChange('nome', options)
    },
    email: {
      id: 'email',
      placeholder: 'Digite seu email...',
      label: 'E-mail',
      onChange: (options: any) => handleChange('email', options)
    },
    // row0: [
    //   {
    //     id: 'telefone',
    //     placeholder: 'Digite seu telefone...',
    //     label: 'Telefone',
    //     mask: '(99) 99999-9999',
    //     onChange: (options: any) => handleChange('telefone', options)
    //   },
    //   {
    //     id: 'celular',
    //     placeholder: 'Digite seu celular...',
    //     label: 'Celular',
    //     mask: '(99) 99999-9999',
    //     onChange: (options: any) => handleChange('celular', options)
    //   },
    // ],
    // row1: [
    //   {
    //     id: 'cpfCnpj',
    //     placeholder: 'Digite...',
    //     label: 'CPF/CNPJ',
    //     onChange: (options: any) => handleChange('cpfCnpj', options)
    //   },
    //   {
    //     id: 'cep',
    //     placeholder: 'Digite seu CEP...',
    //     label: 'CEP',
    //     mask: '99999-999',
    //     onChange: (options: any) => handleChange('cep', options)
    //   },
    // ],
    // row2: [
    //   {
    //     id: 'endereco',
    //     placeholder: 'Digite seu logradouro...',
    //     label: 'Logradouro',
    //     onChange: (options: any) => handleChange('endereco', options)
    //   },
    //   {
    //     id: 'numeroEndereco',
    //     placeholder: 'N°',
    //     label: 'Número',
    //     onChange: (options: any) => handleChange('numeroEndereco', options)
    //   },
    // ],
    // row3: [
    //   {
    //     id: 'bairro',
    //     placeholder: 'Digite seu bairro...',
    //     label: 'Bairro',
    //     onChange: (options: any) => handleChange('bairro', options)
    //   },
    //   {
    //     id: 'cidade',
    //     placeholder: 'Digite sua cidade...',
    //     label: 'Cidade',
    //     type: 'lookup',
    //     endpoint: lookups.uf ? `/tabela?uf=${lookups.uf.value}&classe=Municípios` : '/tabela?classe=Municípios',
    //     field: 'nome',
    //     multi: false,
    //     onChange: (options: any) => handleChange('cidade', options)
    //   },
    //   {
    //     id: 'uf',
    //     placeholder: 'Digite seu estado...',
    //     label: 'Estado',
    //     type: 'lookup',
    //     endpoint: '/tabela?classe=Unidades+da+Federação',
    //     field: 'nome',
    //     multi: false,
    //     onChange: (options: any) => handleChange('uf', options)
    //   },
    // ],
    // complement: {
    //   id: 'complemento',
    //   placeholder: 'Digite o complemento do endereço...',
    //   label: 'Complemento',
    // },
  };

  function handleChange(key: string, value: any){
    setLookups({ ...lookups, [key]: value})
  }

  function handleChangeUploads(e: any) {
    let reader = new FileReader()
    let image = e.target.files[0]

    reader.readAsDataURL(image);
    reader.onloadend = () => {
      setUploads(image)
    }
  }

  const handleSubmit = async (values: IEntidadePayload, helpers: FormikHelpers<IEntidadePayload>) => {
    helpers.setSubmitting(false);
  }
  
  const handleUpdate = async () => {
    const _payload = userForm.values;
    if(Object.keys(lookups).length > 0){
      const keys = Object.keys(lookups)
      for(let key of keys){
        if(key === 'uf'){
          _payload.idUF = lookups[key].value
        } else if(key === 'cidade'){
          _payload.idCidade = lookups[key].value
        }
      }
      if(uploads){
        const formData = new FormData();
        formData.set("file", uploads);
        const result = await uploadFile(formData)
        if(result){
          _payload.foto = result
          try {
            const result = await updateUser(_payload)
            if(result){
              setIsEditing(false)
              setLookups({})
            }
          } catch (error) {
            console.error('Erro: ', error)
          }
        }
      } else{
        try {
          const result = await updateUser(_payload)
          if(result){
            setIsEditing(false)
            setLookups({})
          }
        } catch (error) {
          console.error('Erro: ', error)
        }
      }
    } else {
      if(uploads){
        const formData = new FormData();
        formData.set("file", uploads);
        const result = await uploadFile(formData)
        if(result){
          _payload.foto = result
          try {
            const result = await updateUser(_payload)
            if(result){
              setIsEditing(false)
              setLookups({})
            }
          } catch (error) {
            console.error('Erro: ', error)
          }
        }
      } else {
        try {
          const result = await updateUser(userForm.values)
          if(result){
            setIsEditing(false)
          }
        } catch (error) {
          console.error('Erro: ', error)
        }
      }
    }

  }

  const validate = (values: IEntidadePayload) => {
    const errors: IEntidadeValidation = {};

    if (!values.nome) {
      errors.nome = '* Obrigatório';
    }

    if (!values.telefone) {
      errors.telefone = '* Obrigatório';
    }
 
    if (!values.email) {
      errors.email = '* Obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Este endereço de e-mail é inválido';
    }
 
    return errors;
  };

  const userForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const renderUserInfo = () => {
    return (
      <>
        <fieldset>
          <span>Nome</span>
          <p>{user?.nome}</p>
        </fieldset>
        <fieldset>
          <span>E-mail</span>
          <p>{user?.email}</p>
        </fieldset>
        {/* <fieldset>
          <span>CPF</span>
          <p>{user?.cpfCnpj?.replaceAll('_', '')}</p>
        </fieldset>
        <fieldset>
          <span>Celular</span>
          <p>{user?.celular}</p>
        </fieldset>
        <fieldset>
          <span>Estabelecimento</span>
          <p>{user?.Estab?.nome}</p>
        </fieldset> */}
        {/* <fieldset>
          <span>Grupo</span>
          <p>Nome do Grupo</p>
        </fieldset> */}
        {/* <fieldset>
          <span>Endereço</span>
          <p>{user?.endereco}, {user?.numeroEndereco}{user?.complemento && `, ${user.complemento}`}. {user?.bairro}, {user?.Cidade?.nome} - {user?.UF?.nome} ({user?.cep}).</p>
        </fieldset> */}
      </>
    );
  }

  const renderUserForm = () => {
    return (
        <Form onSubmit={userForm.handleSubmit}>
          <FormInputs fields={fields} form={userForm} />
        </Form>
    );
  }

  return (
    <Container>
      <PaddingContainer>
        <Row>
          <Title>Bem-vindo(a), {user?.nome}</Title>
          <button onClick={signOut}>
            <IoMdExit size={32} color="var(--primary-text-color)" />
          </button>
        </Row>
        <Content>
            <ProfilePhotoContainer>
                <ProfilePhotoButton>
                <ProfilePhoto src={user?.foto || ''} alt="foto do usuário" />
                {isEditing === true &&
                  <ProfilePhotoButtonOverlay>
                      <label htmlFor='photo'>Escolher a foto de perfil</label>
                      <input 
                        type="file" 
                        name="photo" 
                        id="photo" 
                        onChange={(options) => handleChangeUploads(options)}
                        />
                  </ProfilePhotoButtonOverlay>
                }
                </ProfilePhotoButton>
            </ProfilePhotoContainer>
            <ProfileInfoContainer>
                <header>
                <p>Informações de contato</p>
                <button onClick={() => setIsEditing(!isEditing)}>editar</button>
                </header>
                <hr />
                {isEditing ? renderUserForm() : renderUserInfo()}
            </ProfileInfoContainer>
        </Content>
      </PaddingContainer>
      <ProfileSaveContainer showing={isEditing}>
        <Button onClick={() => handleUpdate()}>Salvar</Button>
        <Button onClick={() => setIsEditing(false)}>Cancelar</Button>
      </ProfileSaveContainer>
    </Container>
  );
};

export default Profile;