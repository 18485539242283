import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React from 'react';

import { Container } from './styles';

interface IAdCardProps {
    content: any;
}

const AdCard: React.FC<IAdCardProps> = ({content}) => {
  return (
    <Container>
        <div>
            <div>
                <img src={content.user.photo} alt={content.user.name} />
            </div>
            <div>
                <p>[DEMO]</p>
                <span>{format(content.ad.startDate, 'dd/MM/yy HH:mm', {
                    locale: ptBR,
                })}</span>
            </div>
        </div>
        <div>
            <div>
                <img src={content.ad.media} alt={content.ad.subcontent} />
            </div>
            <div>
                <p>{content.ad.content}</p>
                <p>{content.ad.subcontent}</p>
            </div>
            <div>
                <input value={content.ad.link} readOnly />
            </div>
        </div>
    </Container>
  );
}

export default AdCard;