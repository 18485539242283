/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import TextInput from '../../../components/TextInput';
import {
  Wrapper,
  HeaderContent,
  Title,
  Content,
  FormContainer,
  Button,
} from '../styles';
import Table from '../../../components/Table';
import { Column } from '../../../components/Table/types';
import ImageInput from '../../../components/ImageInput';
import { useFormik } from 'formik';
import { uploadFile } from '../../../services/upload';
import Toast from '../../../components/Toast';
import { isObject } from '../../../utils/form';

interface IAction {
  id: string;
  name: string;
  type: "global" | "specific";
  onClick?: any;
}

interface ITemplateProps extends RouteComponentProps {
  title: string;
  actions: Array<IAction>;
  endpoint: "entidades"
  | "userGroups"
  | "classes"
  | "permissoes"
  | "pedidos"
  | "titulos"
  | "tabelas"
  | "recursos";
  filters?: any;
  path?: string;
  pullOrderPath?: string;
  multipleSelect?: boolean;
}

const CatalogForm: React.FC<ITemplateProps> = ({
  title = 'Inserir produto',
  actions = [],
  endpoint,
  path = '/catalogo/inserir',
  pullOrderPath,
  multipleSelect,
  filters,
  history,
  ...props
}) => {
  const location = useLocation<any>();
  const requestHandler = useFetch({endpoint: 'recursos/produto-site'}) as any;
  const [toEdit, setToEdit] = useState<any>(null);
  const [lookups, setLookups] = useState<any>({});
  const [uploads, setUploads] = useState<any>({});
  const [tables, setTables] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>({});
  const [initialUploadStates, setInitialUploadStates] = useState<any>({});
  const [initialLookupStates, setInitialLookupStates] = useState<any>({});
  const [loadedLookups, setLoadedLookups] = useState(false);
  const [loadedUploads, setLoadedUploads] = useState(false);
  const objectiveCols: any[] = [
    {
        campo: 'nome',
        nome: 'Título',
        tipo: 'texto',
        editavel: true,
    },
  ];
  const benefitsCols: any[] = [
    {
        campo: 'codigo',
        nome: 'Código',
        tipo: 'texto',
        editavel: true,
    },
    {
        campo: 'nome',
        nome: 'Título',
        tipo: 'texto',
        editavel: true,
    },
  ];
  const testimonialsCols: Column[] = [
    {
        campo: 'nome',
        nome: 'Título',
        tipo: 'texto',
        editavel: true,
    },
    {
        campo: 'idade',
        nome: 'Idade',
        tipo: 'texto',
        editavel: true,
        mascara: 'inteiro',
        maxlength: 3,
    },
    {
        campo: 'profissao',
        nome: 'Profissão',
        tipo: 'texto',
        editavel: true,
    },
    {
        campo: 'avaliacao',
        nome: 'Avaliação',
        tipo: 'texto',
        editavel: true,
        mascara: 'inteiro',
        maxlength: 1,
    },
    {
        campo: 'comentario',
        nome: 'Comentário',
        tipo: 'texto',
        editavel: true,
    },
  ];
  const commonQuestionsCols: any[] = [
    {
        campo: 'codigo',
        nome: 'Código',
        tipo: 'texto',
        editavel: true,
    },
    {
        campo: 'nome',
        nome: 'Título',
        tipo: 'texto',
        editavel: true,
    },
  ];
  const tableActions: any[] = ['insert', 'delete'];


  useEffect(() => {
    if (location && location.state) {
      const state: any = {
        item: location?.state?.item,
      };

      if (state.item) {
        setInitialValues(state.item);
        setToEdit(state.item);
      }
    }
  }, [location]);


  const validate = () => {}
  const handleSubmit = async (values: any) => {
    let adjustedPayload = {...values};

    if (uploads) {
      const keys = Object.keys(uploads);

      for (const key of keys) {
        const value = uploads[key];
        const formData = new FormData();
        formData.set("file", value);
        try {
          const url = await uploadFile(formData);
          adjustedPayload[key] = url;
        } catch (err: any) {
          Toast.show(err.message, "error");
        }
      }
    }
    if (tables && Object.keys(tables).length > 0) {
      const keys = Object.keys(tables);

      for (const key of keys) {
        adjustedPayload[key] = tables[key];
      }
    }

    if (location && location.state) {
      //se estiver editando verificar os campos editados.
      const { item } = location.state as any;
      for (const key of Object.keys(item)) {
        if (Array.isArray(item[key]) || isObject(item[key])) {
          //verifica se o campo atual e um object ou array ou undefined (nao foi alterado)
          continue;
        } else {
          if (adjustedPayload[key] === item[key]) {
            //se houve modificação no campo adicionar no payload para ser enviado.
            delete adjustedPayload[key];
          }
        }
      }
    } else {
      for (const key of Object.keys(adjustedPayload)) {
        if (adjustedPayload[key] === null) {
          delete adjustedPayload[key];
        }
      }
    }
    
    try {
      if (toEdit) {
        const response = await requestHandler.put(
          `/${toEdit.id}`,
          adjustedPayload
        );
        if (!response.error) {
          if (history) {
            history.goBack();
          }
          form.setSubmitting(false);
        }
      } else {
        const response = await requestHandler.post(adjustedPayload);
        if (!response.error) {
          if (history) {
            history.goBack();
          }
          form.setSubmitting(false);
        }
      }
    } catch (err: any) {
      form.setSubmitting(false);
    }
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });


  function handleChangeTables(field: string, value: any) {
    return setTables((prevState: any) => {
      return { ...prevState, [field]: value };
    });
  }

  function handleChangeUploads(field: string, options: any) {
    return setUploads((prevState: any) => {
      return { ...prevState, [field]: options };
    });
  }

  return (
    <Wrapper>
      <HeaderContent direction={'row'}>
        <Title>{toEdit ? 'Editar produto' : title}</Title>
      </HeaderContent>
    <FormContainer onSubmit={form.handleSubmit} id="custom-form">
        <ImageInput
          id={'foto'}
          name={'Foto'}
          require={true}
          initialState={initialValues['foto']}
          error={form.errors['foto'] ? true : false}
          onChange={(file) => handleChangeUploads('foto', file)}
        />
        <TextInput
        id="nome"
        name="nome"
        placeholder="Digite o nome do produto..."
        label="Nome"
        require={true}
        value={form.values.nome}
        error={form.errors.nome ? form.errors.nome as string : ''}
        onChange={form.handleChange}
        />
        <Table
        title={'Objetivos'}
        cols={objectiveCols}
        actions={tableActions}
        initialValue={initialValues['objetivos']}
        onChange={(data: any) => handleChangeTables('objetivos', data)}
        fromForm
        />
        <Table
        title={'Benefícios'}
        cols={benefitsCols}
        actions={tableActions}
        initialValue={initialValues['beneficios']}
        onChange={(data: any) => handleChangeTables('beneficios', data)}
        fromForm
        />
        <Table
        title={'Testemunhas'}
        cols={testimonialsCols}
        actions={tableActions}
        initialValue={initialValues['testemunhas']}
        onChange={(data: any) => handleChangeTables('testemunhas', data)}
        fromForm
        />
        <Table
        title={'Perguntas Frequentes'}
        cols={commonQuestionsCols}
        actions={tableActions}
        initialValue={initialValues['perguntasFrequentes']}
        onChange={(data: any) => handleChangeTables('perguntasFrequentes', data)}
        fromForm
        />
        <Button variant="filled" type="submit">Salvar</Button>
    </FormContainer>
    </Wrapper>
  );
}

export default CatalogForm;