import styled from 'styled-components';

export const ToolBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 12px;
  width: 100%;
  margin-bottom: 4px;

  .react-icons-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
  }
`;

export const Title = styled.p`
  font-size: 12px;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-overflow: none;
`;