import styled from 'styled-components';

export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin: 16px 0;

    & button {
        width: 150px;
    }
  }
`;
