import styled from 'styled-components';

export const TableContainer = styled.table`
  border-spacing: 0 5px 0;
  width: 100%;
  height: 100%;

  overflow-x: auto;
  white-space: nowrap;

  margin-bottom: 10px;
  border: 1px solid var(--opacity-background);
  border-collapse: collapse;
  border-radius: 4px;

  /* & td:first-child {
    width: auto;
  } */
`;

export const TableBody = styled.tbody`
  width: 100%;
  text-align: center;
  font-size: 14px;

  & span {
    font-size: 12px;
  }
`;

export const Disabled = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 999;
`;