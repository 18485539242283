import React from 'react';
import { Container } from './styles';

interface ICardCheckProps {
  image: string;
  name: string;
  selected: boolean;
  onClick: any;
}

const CardCheck: React.FC<ICardCheckProps> = ({
  image,
  name,
  selected,
  onClick,
}) => {
  return (
    <Container onClick={onClick} type="button">
        <div>
            <img src={image} alt="" />
        </div>
        <div>
            <div className={selected ? "select active" : "select"}><div /></div>
            <p>{name}</p>
        </div>
    </Container>
  );
}

export default CardCheck;