import styled from 'styled-components';

interface ILabel {
  bold?: boolean;
  error?: boolean;
}

export const Page = styled.div`
  height: auto;
  width: auto;
  display: flex;
  alignt-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: auto;
  width: auto;
  border-radius: 10px;
  background-color: var(--white);
  margin: 4px 0px;

  input[type="file"] {
    display: none;
  }

  & span {
    color: red;
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const ImageHolder = styled.div`
  margin: auto;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border: 1px solid var(--opacity-background);
  border-radius: 5px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Label = styled.div<ILabel>`
  width: 100%;
  display: flex;
  justify-content: center;
  
  label {
    margin: auto;
    margin-top: 8px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${props => props.error ? 'red' : 'var(--primary-text-color)'};
    font-weight: ${props => props.bold ? '500': '300'};

    & svg {
      margin-right: 8px;
    }
  }
`;