import styled from 'styled-components';

interface SelectProps {
  width: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
  fullwidth: boolean;
  error?: string;
  bold?: boolean;
  validateError?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: none;


  & .hint {
    color: var(--muted);
    font-size: 10px;
    margin-bottom: 8px;
  }
`;

export const Label = styled.label<SelectProps>`
  font-size: 12px;
  font-weight: ${props => props.bold ? '500':'300'};
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-overflow: none;
  color: ${props => props.validateError ? 'red' : 'auto'};

  & span {
    color: red;
    margin-left: 4px;
  }

`;

export const Select = styled.select<SelectProps>`
  width: 100%;
  min-height: 35px;
  border-radius: 4px;
  background-color: var(--white);
  border: ${({ error }) => 
  (error || error?.length) ? '1px solid var(--warning)' : '1px solid var(--opacity-background)'};
  padding: 0px 8px 0px 8px;
  white-space: nowrap;
  text-overflow: none;

  &:focus {
    border: 1px solid var(--primary);
  }
`;

export const Error = styled.p`
  margin-top: 4px;
  color: red;
`;