import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--opacity-background);
    border-radius: 4px;

    & > div {
        width: 100%;
        padding: 8px;
    }

    & > div:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    & > div:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    h1 {
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: 300;
        div {
            width: 16px;
            height: 20px;
            border-radius: 50%;
            color: var(--white);
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & > div.active {
        background-color: green;
        color: var(--white);
        div {
            background-color: transparent;
        }
    }

    & > div:not(.active) {
        color: var(--muted);

        div {
            color: var(--muted);
        }
    }


    margin-bottom: 32px;
`;