import styled from 'styled-components';
import { Column } from '../types';
// width: ${({ size }) => {
//   switch (size) {
//     case 'small':
//       return '50px';
//     case 'medium':
//       return '100px';
//     case 'large':
//       return '150px';
//     default:
//       return '100px';
//   }
// }};

export const Cell = styled.td<Column>`
  height: 30px;
  width: auto;
  text-align: ${({ align }) => align};

  font-size: 14px;
  vertical-align: center;
  border-bottom: 1px solid var(--opacity-background);
  white-space: nowrap;
  padding: 8px;

  & input {
    background-color: ${({ editable }) => editable ? 'inherit' : 'var(--disabled)'};
    border-radius: inherit;
    width: 100%;
    height: inherit;
    text-align: ${({ align }) => align};
  }

  & input:focus {
    border: 1px solid var(--primary);
  }
`;